import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player';
import { Spin } from 'antd';




const PlayerComponent = ({ 
  streamUrl, 
  playing, 
  setPlaying,
  cargandoPlayer, 
  setCargandoPlayer
}) => {
  
  const MAX_TRIES = 20;
  // const [ playing, setPlaying ] = useState(false);
  const [ tries, setTries ] = useState(0);
  const [ url, setUrl ] = useState('');
  // const [ readyPlayer, setReadyPlayer ] = useState(false);
  const [ errorMsg, setErrorMsg ] = useState(false);


  const onReady = (e) => {
    // console.log('ready: ',e)
    setPlaying(true)
    // setReadyPlayer(true);
    setErrorMsg(false);
    setCargandoPlayer(false)
    console.log('listo')
  }

  const onError = (e) => {
    if( tries <= MAX_TRIES ) {
      setUrl('');
      setCargandoPlayer(true)
      setTimeout(() => {
        setUrl(streamUrl)
      }, 3000);
      setTries(tries => tries + 1);
    } else {
      setCargandoPlayer(false)
      setTries(0)
      setErrorMsg(true)
    }
  };

  const onStart = () => {

  };

  useEffect(() => {
    setUrl(streamUrl)
  }, [streamUrl])

  console.log('cargandoPlayer: ', cargandoPlayer)

  
  return (
    <>
      { errorMsg && <h3> No se pudo cargar estación </h3>  }
      { cargandoPlayer && !errorMsg  ? <Spin /> : null  }

      <ReactPlayer
        width={"100%"}
        className="player"
        height={100}
        style={{ opacity: !cargandoPlayer && !errorMsg ? 1 : 0  }}
        url={url}
        playing={playing}
        controls={true}
        onReady={onReady}
        onError={onError}
        onStart={onStart}
      />
    </>
  );






}

export default PlayerComponent