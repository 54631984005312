import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AuthLayout } from "../components/layouts";
import SignIn from "../views/auth/SignIn";

const PublicRouter = () => {
  return (
    <Router>
      <AuthLayout>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route path="*" component={() => <Redirect to="/" />} /> 
        </Switch>
      </AuthLayout>
    </Router>
  );
};

export default PublicRouter;
