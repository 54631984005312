import React from "react";
import { Result } from "antd";

const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Página no encontrada, intenta nuevamente."
    />
  );
};

export default NotFound;
