import React from 'react';
import { 
  Row, 
  Col, 
  Card 
} from 'antd';
import { 
  DesktopOutlined,
  HistoryOutlined
} from '@ant-design/icons';

import { useHistory } from "react-router-dom";
import DefaultLayout from '../../components/layouts/DefaultLayout';
import '../../styles/Home.less'

const Home = () => {


  const history = useHistory();
    const { Meta } = Card;

    const styles = {
      textCenter: {
        textAlign: 'center'
      },
      iconSize: {
        fontSize: 40,
        marginBottom: 20
      }
    }

    const cards = [
      {
        id: 0,
        title: 'HISTÓRICO',
        icon: <HistoryOutlined style={ styles.iconSize } />, 
        desc:' ',
        url: '/historico',
      },
      {
        id: 2,
        title: 'MONITOR',
        icon: <DesktopOutlined style={ styles.iconSize } />, 
        desc:' ',
        url: '/monitor', 
      },
    ];

  return(
    <>
    <DefaultLayout className="site-card-wrapper" title="METRICO">
      <Row gutter={{ xs: 2, sm: 1, md: 2, lg: 10, xxl: 10}} >
        { cards.map( item  => (
          <Col
            key={item.id} 
            onClick={ ()=> history.push(item.url) }
            className="gutter-row" 
            xs={{ span: 24, offset: 0 }} 
            sm={{ span: 12, offset: 0 }} 
            md={{ span: 12, offset: 0 }} 
            lg={{ span: 6, offset: 0 }}  
            xxl={{ span: 6, offset: 0 }}
          >
            <Card
              hoverable
              style={{ width: '100%' }}
            >
              <div style={ styles.textCenter }>
                {item.icon}
                <br />
                <h4>{item.title}</h4>
              </div>
            </Card>
          </Col>
        ) ) }
      </Row>
    </DefaultLayout>
    </>
  )

};

export default Home;