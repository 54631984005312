import React from "react";
import {
  HomeOutlined,
  LogoutOutlined,
  DesktopOutlined,
  HistoryOutlined
} from "@ant-design/icons";

import NotAuthorizedView from "../views/shared/NotAuthorized";
import NotFoundView from "../views/shared/NotFound";

import { Home } from "../views/home";
import { Monitor } from "../views/monitor";
import { Historico } from "../views/historico";


const singOutRoute = () => {   
  
  return( "Cargando..." )
}

const sharedRoutes = [
  {
    path: "/no-autorizado",
    component: NotAuthorizedView,
  },
  {
    path: "/salir",
    icon: LogoutOutlined,
    component: singOutRoute,
  },
  {
    path: "*",
    component: NotFoundView,
  },
];

const dashboardRoutes = [
  {
    layout: "dashboard",
    path: "/",
    name: "Inicio",
    icon: <HomeOutlined />,
    sidebar: "single",
    component: Home,
  },
  {
    layout: "dashboard",
    path: "/monitor",
    name: "Monitor",
    icon: <DesktopOutlined />,
    sidebar: "single",
    component: Monitor,
  },
  {
    layout: "dashboard",
    path: "/historico",
    name: "Histórico",
    icon: <HistoryOutlined />,
    sidebar: "single",
    component: Historico,
  },
  ...sharedRoutes,
];

const publicRoutes = [...sharedRoutes];

export { dashboardRoutes, publicRoutes };