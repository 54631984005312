import { Content } from "antd/lib/layout/layout";
import {BreadCrumb, HeaderPage} from "../";
import { Helmet } from 'react-helmet';

const DefaultLayout = ({
  children,
  breadcrumbItems,
  buttonData,
  title,
  breadcrumbDisable = false,
  nameClass=true
}) => {
  const styles = {
    padding: {
      marginLeft: 15,
      marginRight: 15,
    },
    content: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
      minHeight: 280,
      borderRadius: 5,
    },
  };

  return (
    <div style={styles.padding}>
      <Helmet><title>{title || "Página sin titulo"}</title></Helmet>
      {!breadcrumbDisable && <BreadCrumb breadcrumbItems={breadcrumbItems} />}

      <HeaderPage title={title || "Página sin titulo"} buttonData={buttonData} />

      <Content className={ nameClass ? 'site-layout-background' : '' } style={styles.content}>
        {children}
      </Content>
    </div>
  );
};

export default DefaultLayout;