import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Card, DatePicker, Button, Divider, Table, message  } from 'antd';
import { SearchOutlined, PlayCircleOutlined, PauseCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { ref, getDownloadURL, deleteObject, getStorage } from 'firebase/storage'
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import { storage }  from '../../services/firebase';
import { DefaultLayout } from '../../components/layouts';
import { ViewLoading } from '../../components';
import StatusResponse from '../../services/statusResponse';

import ReactPlayer from 'react-player'

import locale from 'antd/es/date-picker/locale/es_ES';
import 'moment/locale/es-mx';
import moment from 'moment';

const Historico = () => {

  const { Option } = Select;
  const [ ciudades, setCiudades ] = useState([])
  const [ estaciones, setEstaciones ] = useState([])
  const [ cargandoCiudades, setCargandoCiudades ] = useState(true)
  const [ fecha, setFecha ] = useState('');
  const [ clave, setClave ] = useState('');
  const [ idCiudad, setIdCiudad ] = useState('')
  const [ nombreCiudad, setNombreCiudad ] = useState('')
  const [ frecuencia, setFrecuencia ] = useState('')
  const [ siglas, setSiglas ] = useState('')
  const [ cargandoHistorico, setCargandoHistorico ] = useState(false);
  const [ historico, setHistorico ] = useState([])
  const [ selectedAudios, setSelectedAudios ] = useState([]);
  const [ progreso, setProgreso ] = useState(null)


  const [ url, setUrl ] = useState(""); 
  const [ playing, setPlaying ] = useState(false);
  const [ idPlaying, setIdPlaying ] = useState(null);
  const [ idLoading, setIdLoading ] = useState(null);
  const [ indexAudio, setIndexAudio ] = useState([null]);

  const [ descargando, setDescargando ] = useState(false)

  const columns = [
    {
      title: 'Reproducir',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 100,
      render: (_, item) => (
      <Button 
          key={item.id}
          type={ playing && idPlaying === item.id ? 'primary' : 'dashed' } 
          shape="circle" 
          loading={item.id === idLoading}
          onClick={ () => 
            {
              setIndexAudio(item.index+1)
              if( playing && idPlaying === item.id ){
                stopPlayer();
              } else {
                updateUrl(item.id, item.archivo);
              }
            } 
          } 
          icon={ playing && idPlaying === item.id ? <PauseCircleOutlined /> : <PlayCircleOutlined /> } 
      />
      ),
      align: 'center',
    },
    {
      title: 'Registro',
      dataIndex: 'registro',
      key: 'registro',
      render: ( _, item ) => (
        <h4 style={{ textTransform: 'uppercasegm' }}>
          { moment(item.registro).format('MMMM D YYYY, h:mm:ss a') }
        </h4>
      ) 
    },
  ];

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setProgreso(null)
      setSelectedAudios( selectedRows);
    },
  };

  const getAudioFileName = audio => {
    return audio.name.replace(/:/g,'-').split(' ').join('_');
  }

  const getBinaryAudios = (audiosToDownload) => {
    const binaryAudiosFetches = audiosToDownload.map(audio => {
      return new Promise((resolve, reject) => {
        let downloadUrl = ref(storage, `${idCiudad}/${clave}/${audio.archivo}` );
        getDownloadURL(downloadUrl)
        .then( url => {
          JSZipUtils.getBinaryContent(url, (error, data) => {
            if (error) throw error;
            resolve({ name: downloadUrl, data });
            setProgreso(progreso => progreso + 1)
          });
          }
        )
        
      });
    });
    return Promise.all(binaryAudiosFetches);
  }

  const descargarAudios = async () => {
    try {
      setDescargando(true);
      setProgreso(null)
      const zip = new JSZip();
      const folderName =  `${nombreCiudad}-${siglas}-${frecuencia}-${fecha}.zip`;
      const binaryAudios = await getBinaryAudios(selectedAudios.length > 0 ? selectedAudios : historico);
      binaryAudios.forEach((binaryAudio, _) => {
        const audioName = getAudioFileName(binaryAudio.name);
        zip.file(`${nombreCiudad}-${siglas}-${frecuencia}-${audioName}`, binaryAudio.data, { binary: true });
      });
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, folderName);

    } catch (error) {
      console.log('No se pudo descargar audios: ', error);
      message.error('No se pudo descargar audios.');
    } finally {
      setDescargando(false)

    }
  }

  const onEnded = () => {
    setIdLoading(null);
    setPlaying(false);
    setUrl(null);
    if( indexAudio === historico.length ) {
      message.info('Ya no se encontraron más audios.')
      return false;
    }
    const nextAudio = historico.filter(item => item.index  === indexAudio );
    let _idAudio = nextAudio[0]?.id;
    let _ruta = nextAudio[0]?.archivo;
    updateUrl(_idAudio, _ruta);
    setIndexAudio( indexAudio + 1 );
    
  }

  const updateUrl = ( id, ruta ) => {

    setIdLoading(id);
    let downloadUrl = ref( storage, `${idCiudad}/${clave}/${ruta}`);

    if( downloadUrl ) {
      getDownloadURL(downloadUrl)
      .then( url => {
          setUrl(url);
          setPlaying(true);
          setIdPlaying(id);
          setIdLoading(null);
        }
      )
      .catch ( error => {
        message.error('No se pudo encontrar audio.');
        console.log('No se pudo encontrar audio: ' +  error)
      } )
      .finally ( res => {
        setIdLoading(null);
      } );
    } else {
      setIdLoading(null);
    };
  };

  const stopPlayer = () => {
    setIdLoading(null);
    setPlaying(false);
    setUrl(null);
  };

  function onChangeDate(_, dateString) {
    setFecha(dateString)
  };

  const handleChangeCiudad = (id) => {
    const filter  = ciudades.filter( item => item.id === id );
    setEstaciones(filter[0]?.estaciones);
    setNombreCiudad(filter[0]?.nombre)
  };

  const handleChangeEstacion = (id) => {
    try {
      const filter = estaciones.filter(item => item.id === id);
      setClave(filter[0]?.clave)
      setIdCiudad(filter[0]?.idCiudad)
      setFrecuencia(filter[0]?.frecuencia)
      setSiglas(filter[0]?.siglas)
    } catch (error) {
      console.log(error)
    }
  };

  const obtenerCiudades = async () => {
    try {
      const resp = await StatusResponse.get('grupo/sonora');
      if(resp){
        if(resp.status === 200) {
          setCiudades(resp?.resultado)
        }
      }
    } 
    catch (e) {
      console.log('Error al cargar estaciones: ', e);
    }
    finally {
      setCargandoCiudades(false);
    }
  };

  const obtenerHistorico = async () => {

    if( fecha === '' || clave === '' || idCiudad === '' ) {
      message.info('Selecciona ciudad, estación y una fecha')
      return false
    }

    try {
      setCargandoHistorico(true);
      const resp = await StatusResponse.getFullUrl(`https://api.fourier.audio/v1/archivo?ciudad=${idCiudad}&estacion=${clave}&fecha=${fecha}`);
      if( resp ) {
        setHistorico(resp?.resultado?.map( (item, index ) => Object.assign(item, {index: index}) ));
      }
    } catch (error) { 
      console.log('error al obtenr histórico: ', error)
    } finally {
      setCargandoHistorico(false)
    }
  };

  useEffect(() => {
    let mounted = true;
    if( mounted ) {
      obtenerCiudades(); 
    }
    return () => mounted = false;
  }, []);

  if( cargandoCiudades ) return <ViewLoading />
  
  return (
    <DefaultLayout nameClass={false} title="Histórico">
      <Row gutter={10}>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h4>Selecciona una ciudad: </h4>
            <Select
              placeholder="Selecciona una estación"
              style={{ width: "100%" }}
              onChange={ (val) => {
                setEstaciones([])
                handleChangeCiudad(val)
              } }
            >
              {ciudades?.map((ciu) => (
                <Option key={ciu?.id} value={ciu?.id}>
                  {ciu?.nombre}
                </Option>
              ))}
            </Select>
          </Card>

          <Card style={{ marginTop: 10 }}>
            <h4>Estaciones: </h4>
            <Select
              placeholder={'Selecciona'}
              style={{ width: "100%" }}
              onChange={handleChangeEstacion}
            >
              {estaciones.length > 0 && (
                <>
                  {estaciones?.map((est) => (
                    <Option key={est?.id} value={est?.id}>
                      {est?.frecuencia} {est.siglas}
                    </Option>
                  ))}
                </>
              )}
            </Select>

            <Divider />

            <DatePicker 
              onChange={onChangeDate} 
              style={{ width: '100%' }}
              locale={locale}
              format="YYYY-MM-DD"
            />

            <Divider />

            <Button 
              icon={<SearchOutlined />}
              type='primary'
              block
              size='large'
              disabled={cargandoHistorico}
              loading={cargandoHistorico}
              onClick={ () => obtenerHistorico() }
            >
              Buscar audios
            </Button>

            <Divider />
            
            <Button 
              icon={<DownloadOutlined /> }
              type='dashed'
              block
              size='large'
              disabled={historico.length < 1 || selectedAudios.length < 1 }
              loading={descargando}
              onClick={ () => descargarAudios() }
            >
              Descargar audios
            </Button>
            { progreso !== null && (
              <div style={{ marginTop: 10 }}>
                Progreso: { progreso } / {  selectedAudios.length  }
              </div>
            ) }

          </Card>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16}>
          <Card>
            <ReactPlayer 
              width={'100%'}
              onEnded={onEnded}
              className='player'
              height={ playing ? 50 : 0 } 
              url={url}
              playing={playing}
              controls 
            />
            <Divider />
            <Table 
            rowSelection={{
              ...rowSelection,
            }}
              pagination={false}
              rowKey={'id'}
              columns={columns}
              dataSource={historico}
              size="small"
              loading={cargandoHistorico}
              scroll={{ y: 600}}
            />
          </Card>
        </Col>
      </Row>
    </DefaultLayout>
  );
}

export default Historico;