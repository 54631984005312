import React from "react";
import { useAuth } from "../hooks";
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";

const AppRouting = () => {
  const { session, sessionLoading  } = useAuth();

  if (sessionLoading) return null;

  return (
    <>
      {session ? <PrivateRouter /> : <PublicRouter />}
    </>
  );
};

export default AppRouting;