import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Card  } from 'antd';
import { getDatabase, ref, get, update} from "firebase/database";
import { DefaultLayout } from '../../components/layouts';
import { ViewLoading } from '../../components';
import StatusResponse from '../../services/statusResponse';
import PlayerComponent from './PlayerComponent';

import '../../styles/Monitor.less';

const Monitor = () => {
  const { Option } = Select;
  const [ ciudades, setCiudades ] = useState([])
  const [ estaciones, setEstaciones ] = useState([])
  const [ cargandoCiudades, setCargandoCiudades ] = useState(true)
  const [ streamUrl, setStreamUrl ] = useState('');

  const [ idCiudad, setIdCiudad ] = useState('')
  const [ index, setIndex ] = useState(null);
  const [ playing, setPlaying ] = useState(false);
  const [ cargandoPlayer, setCargandoPlayer ] = useState(false)

  const handleChangeCiudad = (id) => {
    const filter  = ciudades.filter( item => item.id === id );
    setEstaciones(filter[0]?.estaciones);
  };

  const handleChangeStream = async (ciudad, indice, type) => {
    let _type = type === 'tv' ? 'channels' : 'radios';
    try {
      const db = getDatabase();
      if(idCiudad !== ''  && !index ) {
        console.log(`/devices/${idCiudad}/${_type}/${index}`)
        await update(ref(db, `/devices/${idCiudad}/${_type}/${index}`), {
          stream: false,
        });
      } else {
        console.log(`/devices/${ciudad}/${_type}/${indice}`)
        await update(ref(db, `/devices/${ciudad}/${_type}/${indice}`), {
          stream: true,
        });
      }
    } catch (error) {
      console.log('error: ', error)
    }
  }

  const handleChangeEstacion = (id) => {
    setPlaying(false)
    setStreamUrl('');
    setCargandoPlayer(false);
    try {
      const filter = estaciones.filter(item => item.id === id);
      const STREAM_URL = 'https://stream.audiovalid.com';
      const clave = filter[0]?.clave;

      setIdCiudad(filter[0]?.idCiudad);
      const db = getDatabase();
      const starCountRef = ref(db, 'devices/'+filter[0]?.idCiudad);
   
      get(starCountRef).then((snapshot) => {
        const data = snapshot.val();
        const boxes = data?.boxType === 'tv' ? data?.channels : data?.radios;
        for( let i = 0; i < boxes?.length; i++ ) {
          if( boxes[i]?.station === clave || boxes[i]?.channel === clave ) {
            setIndex(i);
            let url = null;
            if(data.boxType === 'radio'){
              url = `${ STREAM_URL }/${ filter[0]?.idCiudad }${ i }`;
            } else if ( data.boxType === 'tv' ) {
              url = `${ STREAM_URL }/tv-${ filter[0]?.idCiudad }${ i }.mp3`
            } else if ( data.boxType === 'radio_internet' ) {
              url = `${ STREAM_URL }/net-${ filter[0]?.idCiudad }${ i }.mp3`
            }
          
            handleChangeStream( filter[0]?.idCiudad, i, data?.boxType  );
            setStreamUrl(url);  

          }
        }
      });
    } catch (error) {
      console.log(error)
    }
  };

  const obtenerCiudades = async () => {
    try {
      const resp = await StatusResponse.get('grupo/sonora');
      if(resp){
        if(resp.status === 200) {
          setCiudades(resp?.resultado)
        }
      }
    } 
    catch (e) {
      console.log('Error al cargar estaciones: ', e);
    }
    finally {
      setCargandoCiudades(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    if( mounted ) {
      obtenerCiudades(); 
    }
    return () => mounted = false;
  }, []);

  if( cargandoCiudades ) return <ViewLoading />
  
  return (
    <DefaultLayout nameClass={false} title="Monitor">
      <Row gutter={10}>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h4>Selecciona una ciudad: </h4>
            <Select
              placeholder="Selecciona una estación"
              style={{ width: "100%" }}
              onChange={ (val) => {
                setEstaciones([])
                handleChangeCiudad(val)
              } }
            >
              {ciudades?.map((ciu) => (
                <Option key={ciu?.id} value={ciu?.id}>
                  {ciu?.nombre}
                </Option>
              ))}
            </Select>
          </Card>

          <Card style={{ marginTop: 10 }}>
            <h4>Estaciones: </h4>
            <Select
              placeholder={'Selecciona'}
              style={{ width: "100%" }}
              onChange={handleChangeEstacion}
            >
              {estaciones.length > 0 && (
                <>
                  {estaciones?.map((est) => (
                    <Option key={est?.id} value={est?.id}>
                      {est?.frecuencia} {est.siglas}
                    </Option>
                  ))}
                </>
              )}
            </Select>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16}>
          <Card>
          { streamUrl !== '' ?
            <PlayerComponent 
              streamUrl={streamUrl} 
              setPlaying={setPlaying} 
              playing={playing} 
              cargandoPlayer={cargandoPlayer} 
              setCargandoPlayer={setCargandoPlayer}
            />
            : <div style={{ width: '100%', textAlign: 'center', padding: '90px 0px' }}>Selecciona una estación</div>
          } 
          </Card>
        </Col>
      </Row>
    </DefaultLayout>
  );
}

export default Monitor